import React from "react";
import Layout from "../../../components/Layout";
import Seo from "../../../components/Seo";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { graphql, useStaticQuery } from "gatsby";
import Chronicle, { TITLE } from "./Chronicle";

const Template = ({ location }) => {
  const {
    events: { edges },
  } = useStaticQuery(graphql`
    query {
      events: allChronicleJson(sort: { fields: date }) {
        edges {
          node {
            date
            formatDate
            content
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Seo
        title={TITLE + " | Über uns"}
        pathname={location.pathname}
        description={`Wirf einen Rückblick zum Anfang der Feuerwehr Arborn mit Hilfe unserer Chronik.`}
      />

      <Breadcrumbs names={["Über uns", TITLE]} location={location} />
      <Chronicle events={edges} location={location} />
    </Layout>
  );
};

export default Template;
